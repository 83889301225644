import type { SVGProps } from 'react';
import { forwardRef } from 'react';

import classNames from 'classnames';

import type { IconName } from '@fortawesome/free-solid-svg-icons';
import { faEarthAmericas } from '@fortawesome/free-solid-svg-icons/faEarthAmericas';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons/faAngleDown';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons/faAngleRight';
import { faCcAmex } from '@fortawesome/free-brands-svg-icons/faCcAmex';
import { faCcDiscover } from '@fortawesome/free-brands-svg-icons/faCcDiscover';
import { faCcJcb } from '@fortawesome/free-brands-svg-icons/faCcJcb';
import { faCcMastercard } from '@fortawesome/free-brands-svg-icons/faCcMastercard';
import { faCcVisa } from '@fortawesome/free-brands-svg-icons/faCcVisa';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons/faCircleNotch';
import { faCopy } from '@fortawesome/free-solid-svg-icons/faCopy';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons/faCreditCard';
import { faEnvelopeOpen } from '@fortawesome/free-solid-svg-icons/faEnvelopeOpen';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import { faLightbulb } from '@fortawesome/free-solid-svg-icons/faLightbulb';
import { faPen } from '@fortawesome/free-solid-svg-icons/faPen';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons/faCirclePlus';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons/faQuestionCircle';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons/faSignInAlt';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons/faTrashAlt';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faAlignLeft } from '@fortawesome/free-solid-svg-icons/faAlignLeft';
import { faAlignRight } from '@fortawesome/free-solid-svg-icons/faAlignRight';
import { faAlignCenter } from '@fortawesome/free-solid-svg-icons/faAlignCenter';
import { faAlignJustify } from '@fortawesome/free-solid-svg-icons/faAlignJustify';
import { faArrows } from '@fortawesome/free-solid-svg-icons/faArrows';
import { faBold } from '@fortawesome/free-solid-svg-icons/faBold';
import { faItalic } from '@fortawesome/free-solid-svg-icons/faItalic';
import { faUnderline } from '@fortawesome/free-solid-svg-icons/faUnderline';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons/faPaperclip';
import { faT } from '@fortawesome/free-solid-svg-icons/faT';
import { faRectangleXmark } from '@fortawesome/free-solid-svg-icons/faRectangleXmark';
import { faCopyright } from '@fortawesome/free-solid-svg-icons/faCopyright';
import { faMinus } from '@fortawesome/free-solid-svg-icons/faMinus';
import { faLink } from '@fortawesome/free-solid-svg-icons/faLink';
import { faClock } from '@fortawesome/free-solid-svg-icons/faClock';
import { faCalendar } from '@fortawesome/free-solid-svg-icons/faCalendar';
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons/faCalendarDays';
import { faCalendarXmark } from '@fortawesome/free-solid-svg-icons/faCalendarXmark';
import { faBell } from '@fortawesome/free-solid-svg-icons/faBell';
import { faExternalLink } from '@fortawesome/free-solid-svg-icons/faExternalLink';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons/faTriangleExclamation';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons/faCircleXmark';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons/faCircleCheck';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons/faCircleInfo';
import { faUserFriends } from '@fortawesome/free-solid-svg-icons/faUserFriends';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons/faUserPlus';
import { faVideo } from '@fortawesome/free-solid-svg-icons/faVideo';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons/faEllipsisVertical';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons/faArrowLeftLong';
import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons/faArrowRightLong';
import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle';
import { faMoon } from '@fortawesome/free-solid-svg-icons/faMoon';
import { faSun } from '@fortawesome/free-solid-svg-icons/faSun';
import { faXmark } from '@fortawesome/free-solid-svg-icons/faXmark';
import { faImage } from '@fortawesome/free-solid-svg-icons/faImage';
import { faCalendarDay } from '@fortawesome/free-solid-svg-icons/faCalendarDay';
import { faCalendarWeek } from '@fortawesome/free-solid-svg-icons/faCalendarWeek';
import { faTag } from '@fortawesome/free-solid-svg-icons/faTag';
import { faFilter } from '@fortawesome/free-solid-svg-icons/faFilter';
import { faGear } from '@fortawesome/free-solid-svg-icons/faGear';
import { faList } from '@fortawesome/free-solid-svg-icons/faList';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faChromecast } from '@fortawesome/free-brands-svg-icons/faChromecast';
import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons/faClockRotateLeft';
import { faArrowRotateRight } from '@fortawesome/free-solid-svg-icons/faArrowRotateRight';
import { faCalendarCheck } from '@fortawesome/free-solid-svg-icons/faCalendarCheck';
import { faNoteSticky } from '@fortawesome/free-solid-svg-icons/faNoteSticky';

type SizeProp =
  | '2xs'
  | 'xs'
  | 'sm'
  | 'lg'
  | 'xl'
  | '2xl'
  | '1x'
  | '2x'
  | '3x'
  | '4x'
  | '5x'
  | '6x'
  | '7x'
  | '8x'
  | '9x'
  | '10x';

type FaIconProps = SVGProps<SVGSVGElement> & {
  icon: IconName;
  size?: SizeProp;
  color?: string;
  spin?: boolean;
  testId?: string;
};

const ICON_REFERENCE = {
  'angle-down': faAngleDown,
  'angle-right': faAngleRight,
  'cc-amex': faCcAmex,
  'cc-discover': faCcDiscover,
  'cc-jcb': faCcJcb,
  'cc-mastercard': faCcMastercard,
  'cc-visa': faCcVisa,
  check: faCheck,
  'circle-notch': faCircleNotch,
  copy: faCopy,
  'credit-card': faCreditCard,
  'envelope-open': faEnvelopeOpen,
  'exclamation-triangle': faExclamationTriangle,
  'exclamation-circle': faExclamationCircle,
  lightbulb: faLightbulb,
  pen: faPen,
  plus: faPlus,
  'circle-plus': faCirclePlus,
  'question-circle': faQuestionCircle,
  'sign-in-alt': faSignInAlt,
  times: faTimes,
  'trash-alt': faTrashAlt,
  'chevron-up': faChevronUp,
  'chevron-down': faChevronDown,
  'chevron-right': faChevronRight,
  'chevron-left': faChevronLeft,
  'align-left': faAlignLeft,
  'align-right': faAlignRight,
  'align-center': faAlignCenter,
  'align-justify': faAlignJustify,
  arrows: faArrows,
  bold: faBold,
  italic: faItalic,
  underline: faUnderline,
  paperclip: faPaperclip,
  t: faT,
  'rectangle-xmark': faRectangleXmark,
  copyright: faCopyright,
  minus: faMinus,
  link: faLink,
  clock: faClock,
  calendar: faCalendar,
  'calendar-xmark': faCalendarXmark,
  bell: faBell,
  'external-link': faExternalLink,
  'triangle-exclamation': faTriangleExclamation,
  'circle-xmark': faCircleXmark,
  'circle-check': faCircleCheck,
  'circle-info': faCircleInfo,
  'user-friends': faUserFriends,
  'user-plus': faUserPlus,
  spinner: faSpinner,
  video: faVideo,
  'earth-americas': faEarthAmericas,
  'calendar-days': faCalendarDays,
  'calendar-day': faCalendarDay,
  'calendar-week': faCalendarWeek,
  'ellipsis-vertical': faEllipsisVertical,
  'arrow-left-long': faArrowLeftLong,
  'arrow-right-long': faArrowRightLong,
  circle: faCircle,
  moon: faMoon,
  sun: faSun,
  xmark: faXmark,
  image: faImage,
  tag: faTag,
  filter: faFilter,
  gear: faGear,
  list: faList,
  envelope: faEnvelope,
  chromecast: faChromecast,
  'clock-rotate-left': faClockRotateLeft,
  'arrow-rotate-right': faArrowRotateRight,
  'calendar-check': faCalendarCheck,
  'note-sticky': faNoteSticky,
};

const FontAwesomeIcon = forwardRef<SVGSVGElement, FaIconProps>((props, ref) => {
  const { icon: iconProps, size, color, spin, className, testId, ...rest } = props;

  const { prefix, iconName, icon } = ICON_REFERENCE[iconProps];

  const [width, height, _ligatures, _unicode, svgPathData] = icon;

  const dataFa = `${prefix}-${iconName}`;

  const fill = color || 'currentColor';

  return (
    <svg
      ref={ref}
      className={classNames(
        `svg-inline--fa fa-${iconName}`,
        'icon',
        { [`fa-${size}`]: size },
        { 'fa-spin': spin },
        className
      )}
      viewBox={`0 0 ${width} ${height}`}
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
      data-fa={dataFa}
      focusable="false"
      data-prefix="fas"
      data-testid={testId}
      fill={color}
      {...rest}
    >
      {Array.isArray(svgPathData) ? (
        <g>
          <path fill={fill} d={svgPathData[0]} />
          <path fill={fill} d={svgPathData[1]} />
        </g>
      ) : (
        <path fill={fill} d={svgPathData} />
      )}
    </svg>
  );
});

export default FontAwesomeIcon;
